import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import AdminAppRedirect from 'components/AdminAppRedirect';
import OrganizationReport from 'containers/reports/OrganizationReport';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Bookmarks from 'containers/bookmarks';
import CallToAction from 'containers/circles/CallToAction';
import Campaign from 'containers/campaign';
import Circles from 'containers/circles';
import Coach from 'containers/coach';
import Error from 'containers/error';
import Home from 'containers/home';
import ManagerReport from 'containers/reports/ManagerReport';
import Paths from 'containers/paths';

import Login from 'containers/auth/Login';
import Logout from 'components/Logout';
import RecoverPassword from 'containers/auth/RecoverPassword';
import Register from 'containers/auth/Register';
import ResetPassword from 'containers/auth/ResetPassword';
import VerifyEmail from 'containers/auth/VerifyEmail';

import Profile from 'containers/profile';

import UnauthenticatedRoute from 'components/UnauthenticatedRoute';
import Zendesk from 'components/Zendesk';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedRoute path="/" exact>
          <Redirect to="/home" />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/home" component={Home} exact />
        <AuthenticatedRoute path="/paths" component={Paths} />
        <AuthenticatedRoute path="/circles" component={Circles} />
        <AuthenticatedRoute path="/coach" component={Coach} />

        <AuthenticatedRoute path="/bookmarks" component={Bookmarks} />
        <AuthenticatedRoute path="/profile" component={Profile} />

        <AuthenticatedRoute path="/admin" component={AdminAppRedirect} />
        <AuthenticatedRoute path="/help" component={Zendesk} exact />
        <AuthenticatedRoute path="/logout" component={Logout} exact />

        <UnauthenticatedRoute path="/login" component={Login} exact />
        <UnauthenticatedRoute path="/register" component={Register} />
        <UnauthenticatedRoute path="/recover" component={RecoverPassword} />
        <Route path="/verify-email" component={VerifyEmail} />
        <UnauthenticatedRoute
          path="/login/password-reset"
          component={ResetPassword}
        />
        <AuthenticatedRoute path="/manager/report" component={ManagerReport} />
        <AuthenticatedRoute
          path="/organization/report"
          component={OrganizationReport}
        />
        <Route path="/campaign-response" component={Campaign} />
        <Route path="/call-to-action" component={CallToAction} />

        {/** REDIRECTS FROM PRE DIGITAL COACH URLS */}
        <Redirect from="/learning" to="/paths" exact />
        <Redirect from="/learning/:programId" to="/paths/:programId" />
        <Redirect
          from="/learning/:programId/p/:pathId/e/:experienceId"
          to="/paths/:programId/p/:pathId/e/:experienceId"
        />
        <Redirect from="/connect" to="/circles" exact />
        <Redirect
          from="/connect/programs/:programId"
          to="/circles/:programId"
        />
        <Redirect from="/discover" to="/coach" exact />

        <AuthenticatedRoute path="*" component={Error} />
      </Switch>
    </BrowserRouter>
  );
};

Routes.displayName = 'Routes';
export default Routes;
