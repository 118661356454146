import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { TextField, DatePicker } from 'components/FormField';
import { useAddServiceUser, useCheckIsServiceAccount } from 'core/api/user';
import {
  successNotification,
  errorNotification,
} from 'components/Notifications';

const UserDialog = ({ open, onClose }) => {
  const intl = useIntl();

  const { mutateAsync: add } = useAddServiceUser();
  const { mutateAsync: check } = useCheckIsServiceAccount();

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required(intl.formatMessage({ id: 'error.input.empty' }))
      .test(
        'isServiceAccount',
        intl.formatMessage({ id: 'error.service.invalid' }),
        async (value) => {
          try {
            await check(value);
            return true;
          } catch {
            return false;
          }
        },
      ),
    startDate: Yup.date()
      .typeError(intl.formatMessage({ id: 'error.date.invalid' }))
      .required(intl.formatMessage({ id: 'error.input.empty' })),
    endDate: Yup.date()
      .typeError(intl.formatMessage({ id: 'error.date.invalid' }))
      .required(intl.formatMessage({ id: 'error.input.empty' })),
  });

  const handleSubmit = async (
    { username, startDate, endDate },
    { setSubmitting },
  ) =>
    await add(
      {
        username,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      },
      {
        onSuccess: () =>
          successNotification(
            intl.formatMessage({
              id: 'profile.settings.service.users.add.success',
            }),
          ),
        onError: () =>
          errorNotification(intl.formatMessage({ id: 'error.generic' })),
        onSettled: () => setSubmitting(false),
      },
    );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">
          <FormattedMessage id="profile.settings.service.users.add.title" />
        </Typography>
      </DialogTitle>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{
          username: '',
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        }}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  label={
                    <FormattedMessage id="profile.settings.service.username" />
                  }
                  name="username"
                  fullWidth
                  margin="dense"
                />
                <DatePicker
                  label={
                    <FormattedMessage id="profile.settings.service.startDate" />
                  }
                  name="startDate"
                  disablePast
                  maxDate={moment().add(30, 'days')}
                  inputProps={{ 'aria-label': 'Start Date (mm/dd/yyyy)' }}
                  TextFieldProps={{
                    fullWidth: true,
                    margin: 'dense',
                  }}
                />
                <DatePicker
                  label={
                    <FormattedMessage id="profile.settings.service.endDate" />
                  }
                  name="endDate"
                  disablePast
                  maxDate={moment().add(30, 'days')}
                  inputProps={{ 'aria-label': 'End Date (mm/dd/yyyy)' }}
                  TextFieldProps={{
                    fullWidth: true,
                    margin: 'dense',
                  }}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                <FormattedMessage id="action.cancel" />
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
              >
                <FormattedMessage id="action.add" />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

UserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

UserDialog.displayName = 'UserDialog';
export default UserDialog;
