import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import SplashScreen from 'components/SplashScreen';
import { useStores } from 'core/hooks/useStores';

const AuthenticatedRoute = observer(({ component, ...rest }) => {
  const { authStore, userStore } = useStores();

  const setTargetUrl = authStore.setTargetUrl;
  const userDataLoaded = userStore.get.resolved && !!userStore.user;
  const userDataPending = userStore.get.pending;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userDataPending) {
          return <SplashScreen />;
        } else if (userDataLoaded) {
          return React.createElement(component, { ...props });
        } else {
          setTargetUrl(`${window.location.pathname}${window.location.search}`);
          return <Redirect to="/login" />;
        }
      }}
    />
  );
});

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

AuthenticatedRoute.displayName = 'AuthenticatedRoute';

export default AuthenticatedRoute;
