import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

import { isProduction } from 'core/utils/common';

/** Wrapper for tracking usage data */
export default class Tracker {
  static initialize = () => {
    // google analytics
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      testMode: !isProduction(),
    });

    // mixpanel
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TRACKING_ID, {
      debug: !isProduction(),
      persistence: 'localStorage',
    });
  };

  static trackUser = (user) => {
    mixpanel.identify(user.user.uid);
    mixpanel.people.set({
      organization_uid: user.organization.uid,
      $first_name: user.employee.firstName,
      $last_name: user.employee.lastName,
      $email: user.employee.workEmail,
      language: user.employee.language.languageCode,
    });
  };

  static resetUser = () => {
    mixpanel.reset();
  };

  static trackPageView = (page, props) => {
    useEffect(() => {
      mixpanel.track_pageview({ page, ...props });
    }, []);
  };

  static trackEvent = (event, props) => {
    mixpanel.track(event, props);
  };
}
