import React, { useRef, useState, useLayoutEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { questionTypeToComponent } from 'components/ContentRenderer/Embeddable/embeddables/Question/questions';

const MAX_HEIGHT = 28; // 1 line of text

const QuestionCard = ({
  id,
  instructions,
  question: { id: questionId, title: questionTitle, contentType, ...question },
  title,
}) => {
  const QuestionComponent = questionTypeToComponent[contentType];

  const [isOverflowing, setIsOverflowing] = useState(false);

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight) {
      setIsOverflowing(ref.current?.clientHeight > MAX_HEIGHT);
    }
  }, []);

  return (
    <Box px={{ xs: 2, sm: 3 }}>
      {title && (
        <Typography
          variant="h2"
          paragraph
          ref={ref}
          sx={{ textAlign: isOverflowing ? 'left' : 'center' }}
        >
          {title}
        </Typography>
      )}
      {instructions && <Typography paragraph>{instructions}</Typography>}
      <Typography paragraph>{questionTitle}</Typography>
      <QuestionComponent id={id} {...question} />
    </Box>
  );
};

QuestionCard.displayName = 'QuestionCard';
export default QuestionCard;
