import update from 'immutability-helper';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { AuthService, UserService } from 'core/services';

export const useGetBadges = () =>
  useQuery('badges', async () => await UserService.getBadges());

export const useGetActivity = (limit = 10) =>
  useInfiniteQuery(
    ['activity', limit],
    async ({ pageParam = 1 }) =>
      await UserService.getActivity({ limit, page: pageParam }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === limit ? pages.length + 1 : undefined,
    },
  );

export const useUpdateShare = () =>
  useMutation(({ contentType, contentCid, shareType, shareUid }) =>
    UserService.updateShare({ contentType, contentCid, shareType, shareUid }),
  );

export const useGetAttribute = (type) =>
  useQuery(
    ['attribute', type],
    async () => await UserService.getAttributesByName({ attributes: [type] }),
  );

export const useUpdateAttribute = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ type, values }) =>
      UserService.updateAttributes([{ name: type, values }]),
    onSuccess: ({ type }, data) => queryClient.resetQueries(['attribute']),
  });
};

export const useGetNotificationPreferences = () =>
  useQuery(
    'notifications',
    async () => await UserService.getNotificationPreferences(),
  );

export const useSetNotificationPreference = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => UserService.updateNotificationPreferences(params),
    onSuccess: (
      { activityTypeId, notificationTypeId, value },
      { notificationIndex },
    ) =>
      queryClient.setQueryData('notifications', (oldData) => {
        const activityIndex = oldData.findIndex(
          (activity) => activity.id === activityTypeId,
        );

        return update(oldData, {
          [activityIndex]: {
            notifications: { [notificationIndex]: { value: { $set: value } } },
          },
        });
      }),
  });
};

// Returns a list of all employee login requests granted TO the current (service) user
export const useGetServiceEmployees = () =>
  useQuery(
    'serviceEmployees',
    async () => await AuthService.getEmployeeLoginList(),
  );

// Returns a list of all user login requests granted BY the current employee
export const useGetServiceUsers = () =>
  useQuery('serviceUsers', async () => await AuthService.getUserLoginList());

export const useAddServiceUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => AuthService.addUserLogin(params),
    onSuccess: (newData) =>
      queryClient.setQueryData('serviceUsers', (oldData) => ({
        ...oldData,
        newData,
      })),
  });
};

export const useRemoveServiceUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }) => AuthService.revokeUserLogin(id),
    onSuccess: (newData) =>
      queryClient.setQueryData('serviceUsers', (oldData) => {
        const index = oldData.findIndex((user) => user.id === newData.id);
        return { ...oldData, [index]: newData };
      }),
  });
};

export const useCheckIsServiceAccount = () =>
  useMutation({
    mutationFn: (username) => AuthService.checkServiceAccount({ username }),
    retry: false,
  });
