import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CategoryDetail from './CategoryDetail';
import CollectionDetail from './CollectionDetail';
import Deck from './Deck';
import Discover from './Discover';

const Coach = ({ match }) => {
  return (
    <Switch>
      <Route path={match.path} component={Discover} exact />
      <Route path={`${match.path}/deck/:deckId`} component={Deck} exact />
      <Route
        path={`${match.path}/categories/:categoryId`}
        component={CategoryDetail}
        exact
      />
      <Route
        path={`${match.path}/collections/:collectionId`}
        component={CollectionDetail}
        exact
      />
    </Switch>
  );
};

export default Coach;
