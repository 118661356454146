import React from 'react';
import PropTypes from 'prop-types';
import { useDroppable } from '@dnd-kit/core';
import { FormattedMessage } from 'react-intl';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ListItem from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/PriorityList/PriorityListItem';
import { SORTED_ID } from 'components/ContentRenderer/Embeddable/embeddables/Activity/activities/PriorityList';

const useStyles = makeStyles((theme) => ({
  sorted: {
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(),
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5, 0),
  },
  unsorted: {},
}));

const PriorityListContainer = ({
  id,
  items,
  options,
  topLabel,
  bottomLabel,
}) => {
  const classes = useStyles();

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <Box mb={3}>
      {id === SORTED_ID && topLabel && (
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {topLabel}
        </Typography>
      )}
      <SortableContext
        id={id}
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <List dense disablePadding ref={setNodeRef} className={classes[id]}>
          {id === SORTED_ID && items.length === 0 && (
            <Typography variant="body2" sx={{ textAlign: 'center', p: 2 }}>
              <FormattedMessage id="priorityList.instructions" />
            </Typography>
          )}
          {items.map((item) => (
            <ListItem containerId={id} key={item} id={item} options={options} />
          ))}
        </List>
      </SortableContext>
      {id === SORTED_ID && bottomLabel && (
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {bottomLabel}
        </Typography>
      )}
    </Box>
  );
};

PriorityListContainer.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  items: PropTypes.arrayOf(PropTypes.string),
};

PriorityListContainer.displayName = 'PriorityListContainer';
export default PriorityListContainer;
