import ChoiceSelection from './ChoiceSelection';
import DragDrop from './DragDrop';
import PriorityList from './PriorityList';
import SingleSelectTableDropdown from './SingleSelectTableDropdown';
import SingleSelectTableToggle from './SingleSelectTableToggle';
import Tagging from './Tagging';

export const activityTypeToComponent = {
  activityChoiceSelection: ChoiceSelection,
  activityDragDrop: DragDrop,
  activityPriorityList: PriorityList,
  activitySingleSelectTableDropdown: SingleSelectTableDropdown,
  activitySingleSelectTableToggle: SingleSelectTableToggle,
  activityTagging: Tagging,
};
