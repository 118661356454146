import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import DragHandleIcon from '@mui/icons-material/DragIndicator';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(),
    justifyContent: 'space-between',
    touchAction: 'none',
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
  sorted: {
    margin: theme.spacing(0.5, 0),
    borderRadius: theme.spacing(),
    backgroundColor: theme.palette.secondary.light,
  },
  unsorted: {
    margin: theme.spacing(1, 0),
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#FBFBFB',
  },
}));

const PriorityListItem = ({ containerId, id, options }) => {
  const classes = useStyles();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  const { label } = options.find((option) => option.value === id);

  return (
    <ListItem
      disableGutters
      ref={setNodeRef}
      style={style}
      className={cn(classes.item, classes[containerId])}
      {...attributes}
      {...listeners}
    >
      {label}
      <ListItemIcon sx={{ minWidth: 0 }}>
        <DragHandleIcon />
      </ListItemIcon>
    </ListItem>
  );
};

PriorityListItem.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  containerId: PropTypes.string.isRequired,
};

PriorityListItem.displayName = 'PriorityListItem';
export default PriorityListItem;
