import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ViewLessIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewMoreIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';

import Chart from 'components/Chart';
import { useBooleanState } from 'core/hooks/useBooleanState';

const DCChart = ({ data, colors, hasMore }) => {
  const theme = useTheme();

  const [isExpanded, toggleIsExpanded] = useBooleanState(false);

  let items = [];
  if (hasMore) {
    const { items: otherItems } = data[data.length - 1];
    items = otherItems;
  }

  return (
    <Box>
      <Chart
        type="donut"
        height={300}
        width="100%"
        series={data.map((item) => item.count)}
        options={{
          labels: data.map((item) => item.title),
          dataLabels: { enabled: false },
          chart: { type: 'donut' },
          legend: { show: false },
          colors: colors,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  value: { ...theme.typography.h1 },
                  total: {
                    show: true,
                    showAlways: true,
                    label: 'Total completed',
                    ...theme.typography.body2,
                  },
                },
              },
            },
          },
        }}
      />
      <Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography sx={{ fontWeight: 'bold' }}>Category</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>%</Typography>
        </Box>
        <Divider />
        {data.map((item, idx) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Box display="flex" alignItems="center">
              <Box
                bgcolor={colors[idx]}
                width={12}
                height={12}
                borderRadius="50%"
                mr={1.5}
              />
              <Typography color="textSecondary">{item.title}</Typography>
            </Box>
            <Typography sx={{ fontWeight: 'bold' }}>
              {Math.round(item.percentage) + '%'}
            </Typography>
          </Box>
        ))}
        {hasMore && (
          <Box>
            <Collapse in={isExpanded}>
              {items.map((item) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  ml={3}
                >
                  <Typography color="textSecondary">{item.title}</Typography>
                  <Typography>{Math.round(item.percentage) + '%'}</Typography>
                </Box>
              ))}
            </Collapse>
            <Button
              sx={{ display: 'flex', m: 'auto' }}
              onClick={toggleIsExpanded}
              endIcon={isExpanded ? <ViewLessIcon /> : <ViewMoreIcon />}
            >
              {isExpanded ? 'View less' : 'View more'}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

DCChart.displayName = 'DCChart';
export default DCChart;
