import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import Tracker from 'core/utils/tracker';
import rootStore from 'core/stores';
import { isProduction } from 'core/utils/common';

Sentry.init({
  dsn:
    'https://6aa5d516239946f28e9adbcca4947822@o1378999.ingest.sentry.io/6691436',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.8,
  enabled: isProduction(),
});

Tracker.initialize();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider {...rootStore}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
