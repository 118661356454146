import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useField } from 'formik';
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import Container from './PriorityListContainer';
import ListItem from './PriorityListItem';

export const SORTED_ID = 'sorted';
export const UNSORTED_ID = 'unsorted';

const PriorityList = ({ id, options, ...props }) => {
  const [{ value: sortedList = [] }, , { setValue: setSortedList }] = useField({
    name: id,
  });

  const [unsortedList, setUnsortedList] = useState(
    options
      .filter((option) => !sortedList.includes(option.value))
      .map((option) => option.value),
  );

  const [activeId, setActiveId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const getContainer = (id) =>
    id === SORTED_ID || sortedList.includes(id)
      ? [SORTED_ID, sortedList, setSortedList]
      : [UNSORTED_ID, unsortedList, setUnsortedList];

  const handleDragStart = ({ active }) => setActiveId(active.id);

  const handleDragOver = ({ active, over }) => {
    const [fromContainerId, fromList, setFromList] = getContainer(active.id);
    const [toContainerId, toList, setToList] = getContainer(over.id);

    // only continue if moved from one container to another
    if (fromContainerId !== toContainerId) {
      // remove item from fromList
      setFromList(fromList.filter((item) => item !== active.id));

      // if dragged to bottom of container (over = container), just add to end of toList
      if ([SORTED_ID, UNSORTED_ID].includes(over.id))
        setToList([...toList, active.id]);
      // if dragged within container (over = listItem), add to specified index in toList
      else {
        const index = toList.indexOf(over.id);
        setToList([
          ...toList.slice(0, index),
          active.id,
          ...toList.slice(index, toList.length),
        ]);
      }
    }
  };

  const handleDragEnd = ({ active, over }) => {
    const [fromContainerId] = getContainer(active.id);
    const [toContainerId, toList, setToList] = getContainer(over.id);

    // only continued if moved within the same container
    if (fromContainerId === toContainerId) {
      setToList(
        arrayMove(toList, toList.indexOf(active.id), toList.indexOf(over.id)),
      );
    }

    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Container
        id={SORTED_ID}
        items={sortedList}
        options={options}
        {...props}
      />
      <Container
        id={UNSORTED_ID}
        items={unsortedList}
        options={options}
        {...props}
      />
      {createPortal(
        <DragOverlay>
          {activeId ? (
            <ListItem
              containerId={getContainer(activeId)[0]}
              id={activeId}
              options={options}
            />
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};

PriorityList.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
};

PriorityList.displayName = 'PriorityList';
export default PriorityList;
