import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PrivacyDialog from 'components/PrivacyDialog';
import { useBooleanState } from 'core/hooks/useBooleanState';

const ProgramListItem = ({
  title,
  openAt,
  closeAt,
  isLocked,
  shouldResume,
  heroImageUrl,
  contentType,
  programLink,
  contentLink,
  showPrivacyPolicy,
  isCompleted,
  completedAt,
  isFeatured,
  featureTitle,
}) => {
  const [dialogIsOpen, toggleDialogOpen] = useBooleanState(false);

  const subtitle = isLocked ? (
    <FormattedMessage
      id="date.unlocked"
      values={{ unlockAt: moment(openAt).fromNow() }}
    />
  ) : contentType === 'path' && isCompleted ? (
    <FormattedMessage
      id="date.completed"
      values={{
        completedAt: moment.utc(completedAt).format('ll'),
      }}
    />
  ) : closeAt ? (
    <FormattedMessage
      id="date.closing"
      values={{ closeAt: moment(closeAt).fromNow() }}
    />
  ) : (
    ''
  );

  const buttonText = (
    <FormattedMessage
      id={
        isLocked || isCompleted
          ? 'action.view'
          : shouldResume
          ? 'action.resume'
          : 'action.start'
      }
    />
  );

  const src = JSON.parse(heroImageUrl);

  return (
    <>
      <ListItem
        secondaryAction={
          <Button
            variant={isLocked || isCompleted ? 'outlined' : 'contained'}
            onClick={toggleDialogOpen}
            component={
              showPrivacyPolicy && !isLocked && !isCompleted ? 'a' : Link
            }
            to={
              showPrivacyPolicy && !isLocked && !isCompleted
                ? null
                : contentType === 'path' && !isLocked && !isCompleted
                ? contentLink
                : programLink
            }
          >
            {buttonText}
          </Button>
        }
        disablePadding
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 2,
          marginTop: 2,
          '>.MuiListItemButton-root': {
            pr: 16,
          },
        }}
      >
        <ListItemButton component={Link} to={programLink} sx={{ p: 2 }}>
          <ListItemAvatar>
            <Avatar variant="square" src={src.url} />
          </ListItemAvatar>
          <Box>
            {isFeatured && (
              <Typography
                variant="caption"
                sx={{
                  bgcolor: '#F0DC7C',
                  px: 0.5,
                  borderRadius: 0.5,
                  display: 'inline-block',
                  fontSize: 10,
                }}
              >
                {featureTitle}
              </Typography>
            )}
            <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
            <Typography variant="body2">{subtitle}</Typography>
          </Box>
        </ListItemButton>
      </ListItem>
      {dialogIsOpen && (
        <PrivacyDialog
          open
          onClose={toggleDialogOpen}
          maxWidth="md"
          link={contentType === 'path' ? contentLink : programLink}
        />
      )}
    </>
  );
};

ProgramListItem.displayName = 'ProgramListItem';
export default ProgramListItem;
