import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import EmptyState from 'components/EmptyState';
import ErrorBoundary from 'components/ErrorBoundary';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import Page from './Page';
import Participants from './Participants';
import ProgramContainer from 'components/ProgramContainer';
import Steps from './Steps';
import Tracker from 'core/utils/tracker';
import { useGetProgram, useGetCirclePreview } from 'core/api/circle';
import { LEARNING_VIEW } from 'core/utils/permissions';

const CircleDetail = observer(
  ({
    match: {
      params: { programId, circleId },
    },
  }) => {
    Tracker.trackPageView('Program', {
      program_type: 'Circle',
      program_id: programId,
    });

    const isPreview = programId === 'preview';

    let { data, isLoading, isSuccess, error } = isPreview
      ? useGetCirclePreview(circleId)
      : useGetProgram(programId);

    if (isPreview && isSuccess) {
      data = { ...data, content: { ...data }, id: 'preview' };
    }

    const breadcrumbs = [
      { label: <FormattedMessage id="navigation.circles" />, path: '/circles' },
      { label: data?.title, path: `/circles/${programId}` },
    ];

    const tabs = [
      {
        value: 'agenda',
        label: <FormattedMessage id="circles.navigation.agenda" />,
        component: Steps,
        props: data,
      },
      {
        value: 'participants',
        label: <FormattedMessage id="circles.navigation.participants" />,
        component: Participants,
        props: data,
      },
      ...(data?.content?.pages || []).map(({ title, content }) => ({
        value: title,
        label: title,
        component: Page,
        props: { content },
      })),
    ];

    return (
      <Gate permissions={[LEARNING_VIEW]} redirectOnDenied>
        <Layout showNavigation color="light">
          {!isLoading && (
            <ErrorBoundary error={error}>
              <ProgramContainer
                breadcrumbs={breadcrumbs}
                tabs={tabs}
                isPreview={isPreview}
                {...data}
              />
            </ErrorBoundary>
          )}
          {isLoading && <EmptyState isLoading />}
        </Layout>
      </Gate>
    );
  },
);

CircleDetail.displayName = 'CircleDetail';
export default CircleDetail;
