import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import AdminIcon from '@mui/icons-material/GppGoodOutlined';
import ArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import PrivacyIcon from '@mui/icons-material/LockOutlined';
import ProfileIcon from '@mui/icons-material/PersonOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Avatar from 'components/Avatar';
import LanguageSelectItem from 'layouts/Layout/components/LanguageSelectItem';
import LogoutItem from 'layouts/Layout/components/LogoutItem';
import NavigationItem from 'layouts/Layout/components/NavigationItem';
import PopoverItem from 'layouts/Layout/components/PopoverItem';
import PrivacyDialog from 'components/PrivacyDialog';
import { useStores } from 'core/hooks/useStores';
import { ADMIN_TOOL_VIEW } from 'core/utils/permissions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(),
    width: 32,
    height: 32,
  },
}));

export const MENU_NAVIGATION = [
  {
    pathname: '/profile',
    label: <FormattedMessage id="navigation.profile" />,
    icon: ProfileIcon,
    GateProps: { noServiceLogin: true },
  },
  {
    pathname: '/admin',
    label: <FormattedMessage id="navigation.admin" />,
    icon: AdminIcon,
    GateProps: { permissions: [ADMIN_TOOL_VIEW] },
  },
  {
    pathname: '/help',
    label: <FormattedMessage id="navigation.help" />,
    icon: HelpIcon,
    ButtonProps: { target: '_blank' },
  },
];

const NavigationMenu = observer(() => {
  const classes = useStyles();

  const { userStore } = useStores();
  const { user } = userStore;

  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = (e) => setAnchorEl(e.currentTarget);
  const closePopover = () => setAnchorEl(null);

  return (
    <div>
      <Box
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={openPopover}
      >
        <Avatar
          className={classes.avatar}
          src={user.user.avatarUrl}
          name={user.user.fullName}
        />
        <Typography>{user.user.preferredName}</Typography>
        <ArrowIcon fontSize="xs" />
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box textAlign="center" bgcolor="background.default" py={2} px={8}>
          <Avatar src={user.user.avatarUrl} name={user.user.fullName} />
          <Typography variant="subtitle1">{user.user.fullName}</Typography>
          <Typography variant="body2">
            {user.organization.displayName}
          </Typography>
        </Box>
        <List>
          {MENU_NAVIGATION.map((props, idx) => (
            <NavigationItem key={idx} color="text.primary" {...props} />
          ))}
          <PopoverItem
            icon={PrivacyIcon}
            label={<FormattedMessage id="navigation.privacy" />}
            popover={PrivacyDialog}
            maxWidth="md"
          />
          <LanguageSelectItem />
          <LogoutItem />
        </List>
      </Popover>
    </div>
  );
});

export default NavigationMenu;
NavigationMenu.displayName = 'NavigationMenu';
