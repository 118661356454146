import React from 'react';
import { FormattedMessage } from 'react-intl';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Alerts from 'components/Alerts';
import Categories from './Categories';
import Collections from './Collections';
import Decks from './Decks';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import Search from './Search';
import Tracker from 'core/utils/tracker';
import { COACH_VIEW } from 'core/utils/permissions';

const Discover = () => {
  Tracker.trackPageView('Digital Coach');

  return (
    <Gate permissions={[COACH_VIEW]} redirectOnDenied>
      <Layout showNavigation showFooter>
        <Typography variant="h2" sx={{ pb: 3 }}>
          <FormattedMessage id="discover.title" />
        </Typography>
        <Stack spacing={2}>
          <Search />
          <Categories />
          <Collections />
          <Decks />
        </Stack>
        <Alerts />
      </Layout>
    </Gate>
  );
};

Discover.displayName = 'Discover';
export default Discover;
