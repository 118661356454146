import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Confetti from 'react-confetti';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import NeutralIcon from '@mui/icons-material/SentimentNeutral';
import Rating from '@mui/material/Rating';
import SatisfiedIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';
import Icon from 'components/SvgIcons/Trophy';
import Onboarding from 'components/Onboarding';
import Tracker from 'core/utils/tracker';
import { useSaveEvent, useSetDeckRating } from 'core/api/coach';
import { useStores } from 'core/hooks/useStores';
import { useBooleanState } from 'core/hooks/useBooleanState';

const icons = {
  1: {
    label: 'Dissatisfied',
    rating: -1,
    icon: <DissatisfiedIcon fontSize="large" color="error" sx={{ mx: 1 }} />,
  },
  2: {
    label: 'Neutral',
    rating: 0,
    icon: <NeutralIcon fontSize="large" color="warning" sx={{ mx: 1 }} />,
  },
  3: {
    label: 'Satisfied',
    rating: 1,
    icon: <SatisfiedIcon fontSize="large" color="success" sx={{ mx: 1 }} />,
  },
};

const LastCard = ({
  deckId,
  confirmationTitle,
  confirmationDescription,
  skills,
  nextUpTitle,
  nextDeck,
}) => {
  const { mutateAsync: saveEvent } = useSaveEvent();
  const { mutate: saveRating } = useSetDeckRating();

  const { userStore } = useStores();
  const { user } = userStore;

  // turn on and off the confetti
  const [showConfetti, setShowConfetti] = useState(true);

  const [rating, setRating] = useState(null);
  const ratingRef = useRef(rating); // ref needed to cache the latest state for later use in useeffect cleanup fn

  const [isOnboarding, toggleIsOnboarding] = useBooleanState(false);

  useEffect(() => {
    // open onboarding dialog after 2 seconds if not yet onboarded
    setTimeout(() => {
      if (!user.hasOnboarded) {
        toggleIsOnboarding();
      }
    }, 2000);
  }, [user.hasOnboarded]);

  useEffect(() => {
    // save deck completed event on last card load
    saveEvent({
      contentType: 'DECK',
      contentId: deckId,
      event: 'completed',
      isSurvey: false,
    });

    Tracker.trackEvent('deck_complete', { deck_id: deckId });
  }, [deckId, saveEvent]);

  useEffect(() => {
    // timer to turn the confetti off after 4 seconds.
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 4000);
    return () => clearTimeout(timer);
  });

  React.useEffect(() => {
    // set rating ref when rating changes
    ratingRef.current = rating;
  }, [rating]);

  useEffect(() => {
    return () => {
      // save rating to db when component unmounts
      if (ratingRef.current)
        saveRating({ id: deckId, rating: icons[ratingRef.current].rating });
    };
  }, []);

  return (
    <>
      <Box textAlign="center" px={{ xs: 2, sm: 3 }}>
        <Icon sx={{ height: 100, width: 100 }} />
        <Typography variant="h1" sx={{ pb: 1 }}>
          {confirmationTitle}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {confirmationDescription}
        </Typography>
        {skills &&
          skills.map((skill) => (
            <Chip
              key={skill.title}
              label={skill.title}
              sx={{ mr: 1, mt: 1, bgcolor: 'background.default' }}
            />
          ))}
      </Box>
      <Confetti
        width={800}
        height={700}
        colors={['#467d86', '#376870', '#b8dfe2']}
        numberOfPieces={200}
        // using showConfetti in the recycle parameter, so it doesn't look weird and get rid of current confetti on screen
        recycle={showConfetti}
      />
      <Box
        bgcolor="background.default"
        p={3}
        my={2}
        mx={{ xs: 0, sm: 3 }}
        borderRadius={{ xs: 0, sm: 2 }}
      >
        <Typography variant="h5">{nextUpTitle}</Typography>
        <CoachListItem {...nextDeck.coachContent[0]} />
        <Box textAlign="center" mt={2}>
          <Typography display="inline">
            <FormattedMessage id="boolean.or" />
          </Typography>
          <Typography
            component={Link}
            to="/coach"
            display="inline"
            color="primary"
            sx={{ textDecoration: 'none' }}
          >
            <FormattedMessage id="action.discover" />
          </Typography>
        </Box>
      </Box>
      <Box textAlign="center">
        <Typography variant="h5">Was this helpful?</Typography>
        <Rating
          IconContainerComponent={({ value, ...props }) => (
            <span {...props}>{icons[value].icon}</span>
          )}
          getLabelText={(value) => icons[value].label}
          highlightSelectedOnly
          max={3}
          onChange={(e, value) => setRating(value)}
          sx={{
            pt: 1,
            '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
              color: 'text.disabled',
            },
          }}
        />
      </Box>
      <Onboarding
        open={isOnboarding}
        onClose={toggleIsOnboarding}
        username={user.user.preferredName}
      />
    </>
  );
};

LastCard.displayName = 'LastCard';
export default LastCard;
