import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PastIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CoachListItem from 'components/CoachListItem';
import EmptyStateImage from 'assets/shared/panda-search.svg';
import useUpdateEffect from 'core/hooks/useUpdateEffect';
import useDebounce from 'core/hooks/useDebounce';
import {
  useGetSearchResults,
  useGetPastSearches,
  useGetPopularSearches,
  useDeleteSearch,
} from 'core/api/feed';

const generateKey = () => {
  let key = '';
  Array.from({ length: 32 }).some(() => {
    key += Math.random().toString(36).slice(2);
    return key.length >= 32;
  });
  return key.slice(0, 32);
};

const SearchResultList = ({ title, ListProps, action, children }) => (
  <Paper
    sx={{ width: '100%', borderRadius: 2, mt: 2 }}
    elevation={0}
    variant="outlined"
  >
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      borderBottom={1}
      borderColor="divider"
    >
      <Typography variant="h6">{title}</Typography>
      {action && action}
    </Box>
    <List {...ListProps}>{children}</List>
  </Paper>
);

const SearchInput = ({ onClose }) => {
  const intl = useIntl();

  const inputRef = useRef();

  const results = useGetSearchResults();
  const past = useGetPastSearches();
  const popular = useGetPopularSearches();
  const { mutate: remove } = useDeleteSearch();

  const [key, setKey] = useState();

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500); // debounced input value

  // debounced search on input change
  useUpdateEffect(() => {
    results.mutate({ key: key, term: debouncedValue });
  }, [debouncedValue]);

  // generate new key on initial render & on input clear
  useEffect(() => {
    if (value === '') setKey(generateKey());
  }, [value]);

  const handleChange = (e) => setValue(e.target.value);

  const handleClear = () => {
    setValue('');
    inputRef.current.focus();
  };

  const handleSelect = (value) => () => setValue(value);

  const handleDeleteTerm = (term) => () => remove({ term });
  const handleDeleteAll = () => remove({ all: true });

  return (
    <Box flex="1 1 auto" display="flex" flexDirection="column" width="100%">
      <Box display="flex">
        <TextField
          value={value}
          onChange={handleChange}
          autoFocus
          fullWidth
          placeholder={intl.formatMessage({ id: 'placeholder.search' })}
          sx={{ bgcolor: 'white', color: 'text.secondary' }}
          InputProps={{
            inputRef: inputRef,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon htmlColor="#525252" />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                size="small"
                onClick={handleClear}
                sx={{ visibility: value ? 'visible' : 'hidden' }}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <Button sx={{ ml: 2, color: 'text.primary' }} onClick={onClose}>
          <FormattedMessage id="action.cancel" />
        </Button>
      </Box>
      {results.isLoading || past.isLoading || popular.isLoading ? (
        <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
      ) : Array.isArray(results.data) ? (
        <SearchResultList
          title={<FormattedMessage id="discover.search.results" />}
          ListProps={{ sx: { px: 2 } }}
        >
          {results.data.length > 0 ? (
            results.data.map((item, idx) => (
              <CoachListItem key={idx} {...item} />
            ))
          ) : (
            <Box textAlign="center" p={10}>
              <img src={EmptyStateImage} width={200} alt="" />
              <Typography>
                <FormattedMessage id="discover.search.empty" />
              </Typography>
            </Box>
          )}
        </SearchResultList>
      ) : (
        <Box>
          <SearchResultList
            title={<FormattedMessage id="discover.search.popular" />}
          >
            {popular.data.map((item, idx) => (
              <ListItemButton key={idx} onClick={handleSelect(item)}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItemButton>
            ))}
          </SearchResultList>
          {past.data.length > 0 && (
            <SearchResultList
              title={<FormattedMessage id="discover.search.recent" />}
              action={
                <Button onClick={handleDeleteAll}>
                  <FormattedMessage id="action.clearAll" />
                </Button>
              }
            >
              {past.data.map((item, idx) => (
                <ListItem
                  key={idx}
                  disablePadding
                  secondaryAction={
                    <IconButton onClick={handleDeleteTerm(item)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton onClick={handleSelect(item)}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <PastIcon />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItemButton>
                </ListItem>
              ))}
            </SearchResultList>
          )}
        </Box>
      )}
    </Box>
  );
};

SearchInput.displayName = 'SearchInput';
export default SearchInput;
