import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ErrorBoundary from 'components/ErrorBoundary';
import { useGetCollections } from 'core/api/coach';

const Collections = () => {
  const { data, isLoading, error } = useGetCollections();

  return (
    <Paper
      sx={{ p: 2, width: '100%', borderRadius: 2, overflowX: 'scroll' }}
      elevation={0}
      variant="outlined"
    >
      {!isLoading && (
        <ErrorBoundary error={error}>
          <Typography variant="h6" sx={{ mb: 1.5 }}>
            <FormattedMessage id="discover.collections.title" />
          </Typography>
          <Box display="flex" alignItems="center">
            {data.map(({ id, title, isFeatured }) => (
              <Box
                key={id}
                component={Link}
                to={`/coach/collections/${id}`}
                margin={0.5}
                bgcolor="muted.blue"
                borderRadius={2}
                height={88}
                minWidth={200}
                display="flex"
                flexDirection="column"
                sx={{
                  textDecoration: 'none',
                  '&:hover': { bgcolor: '#F4F4F5' },
                }}
              >
                {isFeatured && (
                  <Typography
                    sx={{
                      bgcolor: '#F0DC7C',
                      p: 0.5,
                      borderRadius: '8px 8px 0px 0px',
                      textAlign: 'center',
                    }}
                  >
                    <FormattedMessage id="discover.collections.featured" />
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    px: 1.5,
                    color: 'info.dark',
                    margin: 'auto',
                  }}
                >
                  {title}
                </Typography>
              </Box>
            ))}
          </Box>
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Collections.displayName = 'Collections';
export default Collections;
