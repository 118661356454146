import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

const ChoiceSelection = ({ id, choices, rows }) => {
  const [field, , helpers] = useField({ name: id });

  const handleChange = (rowValue) => (e) =>
    helpers.setValue({ ...field.value, [rowValue]: e.target.value });

  return rows.map(({ label: rowLabel, value: rowValue }) => (
    <Box
      key={rowValue}
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="center"
      mb={2}
      border={1}
      borderRadius={2}
      borderColor="divider"
      p={2}
    >
      <Typography sx={{ width: { xs: '100%', md: '50%' } }}>
        {rowLabel}
      </Typography>
      <ToggleButtonGroup
        value={field.value?.[rowValue]}
        aria-label={rowLabel}
        exclusive
        onChange={handleChange(rowValue)}
        size="small"
        color="primary"
        sx={{
          width: { xs: '100%', md: '50%' },
          ml: { xs: 0, md: 2 },
          mt: { xs: 2, md: 0 },
        }}
      >
        {choices.map(({ label: choiceLabel, value: choiceValue }) => (
          <ToggleButton
            key={choiceValue}
            value={choiceValue}
            sx={{
              width: '50%',
              color: 'text.secondary',
              borderColor: 'text.secondary',
              '&.Mui-selected': {
                border: '1px solid #467D86 !important',
                bgcolor: 'secondary.light',
                fontWeight: 'bold',
              },
            }}
            aria-label={choiceLabel}
          >
            {choiceLabel}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  ));
};

ChoiceSelection.propTypes = {
  id: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
};

ChoiceSelection.displayName = 'ChoiceSelection';
export default ChoiceSelection;
