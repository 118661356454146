import React, { useEffect } from 'react';
import { observer, useObserver } from 'mobx-react';
import { QueryClient, QueryClientProvider } from 'react-query';

import ConnectedIntlProvider from 'components/ConnectedIntlProvider';
import Routes from 'components/Routes';
import Notifications from 'components/Notifications';
import Tracker from 'core/utils/tracker';
import { useStores } from 'core/hooks/useStores';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main = observer(() => {
  const { userStore } = useStores();

  const { loggedIn, getUser, user } = useObserver(() => ({
    loggedIn: userStore.loggedIn,
    getUser: userStore.get,
    user: userStore.user,
  }));

  useEffect(() => {
    const fetch = async () => {
      if (!user) {
        // Start fetching the user data but don't await it to make sure <Routes/> only renders once
        getUser();
      }
    };
    fetch();
  }, [loggedIn, getUser, user]);

  useEffect(() => {
    if (user) Tracker.trackUser(user);
  }, [user]);

  return (
    <QueryClientProvider client={queryClient}>
      <ConnectedIntlProvider>
        <Routes />
        <Notifications />
      </ConnectedIntlProvider>
    </QueryClientProvider>
  );
});

export default Main;
