import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';

import PhoneInput from 'components/PhoneInput';

export const isValidPhone = (value) => {
  if (value) return isPossiblePhoneNumber(value);
  return true;
};

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: theme.spacing(6),
  },
  shrink: {
    paddingLeft: 0,
  },
}));

const PhoneField = ({
  label,
  variant,
  disabled,
  helperText,
  InputLabelProps,
  InputProps,
  ...props
}) => {
  const classes = useStyles();

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();

  const showError = meta.touched && !!meta.error;

  return (
    <FormControl
      disabled={isSubmitting || disabled}
      error={showError}
      variant={variant}
      {...props}
    >
      <InputLabel
        htmlFor="phone"
        classes={{ root: classes.label, shrink: classes.shrink }}
        {...InputLabelProps}
      >
        {label}
      </InputLabel>
      <PhoneInput
        id="phone"
        disabled={isSubmitting || disabled}
        label={label}
        variant={variant}
        {...field}
        {...InputProps}
        onChange={(value) =>
          value ? helpers.setValue(value) : helpers.setValue('')
        }
      />
      <FormHelperText>{showError ? meta.error : helperText}</FormHelperText>
    </FormControl>
  );
};

PhoneField.propTypes = {
  variant: PropTypes.string,
};

PhoneField.defaultProps = {
  variant: 'outlined',
};

PhoneField.displayName = 'PhoneField';
export default PhoneField;
