import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import EmptyState from 'components/EmptyState';
import ErrorBoundary from 'components/ErrorBoundary';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import ProgramCard from './ProgramCard';
import Tracker from 'core/utils/tracker';
import { useGetFeed } from 'core/api/feed';
import { LEARNING_VIEW } from 'core/utils/permissions';

const CircleList = () => {
  Tracker.trackPageView('Circles');

  const { data, isLoading, error } = useGetFeed();

  const current = data?.growContent?.currentPrograms.filter(
    (program) => program.contentType === 'circle',
  );

  const past = data?.growContent?.pastPrograms.filter(
    (program) => program.contentType === 'circle',
  );

  return (
    <Gate permissions={[LEARNING_VIEW]} redirectOnDenied>
      <Layout showNavigation showFooter>
        {!isLoading && (
          <ErrorBoundary error={error}>
            <Box>
              <Typography variant="h2" paragraph>
                <FormattedMessage id="circles.current.title" />
              </Typography>
              {current.length > 0 ? (
                <Stack spacing={2}>
                  {current.map((program, idx) => (
                    <ErrorBoundary key={idx}>
                      <ProgramCard {...program} />
                    </ErrorBoundary>
                  ))}
                </Stack>
              ) : (
                <Typography variant="body2">
                  <FormattedMessage id="circles.current.empty" />
                </Typography>
              )}
            </Box>
            <Box pt={4}>
              <Typography variant="h2" paragraph>
                <FormattedMessage id="circles.past.title" />
              </Typography>
              {past.length > 0 ? (
                <Stack spacing={2}>
                  {past.map((program, idx) => (
                    <ErrorBoundary key={idx}>
                      <ProgramCard {...program} />
                    </ErrorBoundary>
                  ))}
                </Stack>
              ) : (
                <Typography variant="body2">
                  <FormattedMessage id="circles.past.empty" />
                </Typography>
              )}
            </Box>
          </ErrorBoundary>
        )}
        {isLoading && <EmptyState isLoading />}
      </Layout>
    </Gate>
  );
};

CircleList.displayName = 'CircleList';
export default CircleList;
