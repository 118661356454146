import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';

import AddBookmarkIcon from '@mui/icons-material/BookmarkAddOutlined';
import ActiveBookmarkIcon from '@mui/icons-material/BookmarkAdded';
import ShareIcon from '@mui/icons-material/IosShareOutlined';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, responsiveFontSizes } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import {
  ActivityCard,
  FirstCard,
  LastCard,
  QuestionCard,
  TextCard,
} from './cards';
import { useSetBookmark } from 'core/api/coach';
import { useUpdateShare } from 'core/api/user';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const cardTypeToComponent = {
  first: FirstCard,
  last: LastCard,
  coachDeckCardText: TextCard,
  coachDeckCardActivity: ActivityCard,
  coachDeckCardQuestion: QuestionCard,
};

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(),
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      border: 'none',
      height: ({ activeStep }) =>
        activeStep === 0 ? '100%' : 'calc(100% - 60px)',
    },
  },
}));

const Card = ({
  id,
  contentType,
  deckId,
  direction,
  progress,
  isBookmarked,
  activeStep,
  color,
  ...props
}) => {
  const classes = useStyles({ activeStep });
  const CardContent = cardTypeToComponent[contentType];

  const queryClient = useQueryClient();

  const { mutate } = useSetBookmark();
  const { mutateAsync: createShare } = useUpdateShare();

  const onBookmarkClick = () =>
    mutate(
      { id: deckId, isBookmarked: !isBookmarked },
      {
        onSuccess: () =>
          queryClient.setQueriesData(['deck'], (data) => ({
            ...data,
            isBookmarked: !isBookmarked,
          })),
      },
    );

  const onShareClick = () => {
    createShare(
      {
        contentType: 'DECK',
        contentCid: deckId,
        shareType: 'SHARE',
      },
      {
        onSuccess: (data) => {
          navigator.clipboard.writeText(data.uurl);
          successNotification('Copied sharable link to clipboard.');
        },
        onError: () => errorNotification('Unable to generate sharable link.'),
      },
    );
  };

  const animate = (direction) => {
    if (direction < 0)
      return {
        zIndex: 2,
        x: ['-100vw', '0vw'],
        y: ['20vh', '0vh'],
        rotate: [-30, 0],
        boxShadow: '0 7px 20px rgba(0,0,0,0.5)',
      };
    else return { zIndex: 1, x: 0, y: 0 };
  };

  const exit = (direction) => {
    if (direction < 0) return { zIndex: 1, x: 0, y: 0 };
    else
      return {
        zIndex: 2,
        x: '-100vw',
        y: '20vh',
        rotate: -30,
        boxShadow: '0 7px 20px rgba(0,0,0,0.5)',
      };
  };

  return (
    <AnimatePresence custom={direction}>
      <motion.div
        key={id || contentType}
        className={classes.card}
        transition={{
          ease: 'easeInOut',
          duration: 1,
          boxShadow: { duration: 0.5 },
        }}
        animate={animate}
        exit={exit}
        custom={direction}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" py={1} px={2}>
            <IconButton component={NavLink} to="/coach">
              <CloseIcon />
            </IconButton>
            <Box display="flex" justifyContent="center" alignItems="start">
              <IconButton onClick={onShareClick} size="small">
                <ShareIcon />
              </IconButton>
              <IconButton onClick={onBookmarkClick} size="medium">
                {isBookmarked ? (
                  <ActiveBookmarkIcon htmlColor={color} />
                ) : (
                  <AddBookmarkIcon />
                )}
              </IconButton>
            </Box>
          </Box>
          {progress}
        </Box>
        <Box py={3} flex="1 1 auto">
          <ThemeProvider
            theme={(theme) =>
              responsiveFontSizes(
                createTheme({
                  ...theme,
                  typography: {
                    ...theme.typography,
                    body1: {
                      ...theme.typography.h6,
                      fontWeight: theme.typography.fontWeightRegular,
                    },
                    h6: {
                      ...theme.typography.h6,
                      fontWeight: theme.typography.fontWeightRegular,
                    },
                    h3: {
                      ...theme.typography.h3,
                      fontWeight: theme.typography.fontWeightRegular,
                    },
                  },
                }),
              )
            }
          >
            <CardContent deckId={deckId} id={id} {...props} />
          </ThemeProvider>
        </Box>
      </motion.div>
    </AnimatePresence>
  );
};

Card.displayName = 'Card';
export default Card;
