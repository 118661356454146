import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import CirclesIcon from '@mui/icons-material/PeopleOutlined';
import CirclesIconActive from '@mui/icons-material/People';
import CoachIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CoachIconActive from '@mui/icons-material/AutoAwesome';
import Drawer from '@mui/material/Drawer';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import HomeIconActive from '@mui/icons-material/Home';
import List from '@mui/material/List';
import PrivacyIcon from '@mui/icons-material/LockOutlined';
import PathsIcon from '@mui/icons-material/BookOutlined';
import PathsIconActive from '@mui/icons-material/Book';
import Toolbar from '@mui/material/Toolbar';

import LanguageSelectItem from 'layouts/Layout/components/LanguageSelectItem';
import LogoutItem from 'layouts/Layout/components/LogoutItem';
import NavigationItem from 'layouts/Layout/components/NavigationItem';
import PopoverItem from 'layouts/Layout/components/PopoverItem';
import PrivacyDialog from 'components/PrivacyDialog';
import { MENU_NAVIGATION } from 'layouts/Layout/Appbar/NavigationMenu';
import { COACH_VIEW, LEARNING_VIEW } from 'core/utils/permissions';

const DRAWER_WIDTH = 300;

const DRAWER_NAVIGATION = [
  {
    pathname: '/home',
    label: <FormattedMessage id="navigation.home" />,
    icon: HomeIcon,
    activeIcon: HomeIconActive,
  },
  {
    pathname: '/coach',
    label: <FormattedMessage id="navigation.discover" />,
    icon: CoachIcon,
    activeIcon: CoachIconActive,
    GateProps: { permissions: [COACH_VIEW] },
  },
  {
    pathname: '/paths',
    label: <FormattedMessage id="navigation.paths" />,
    icon: PathsIcon,
    activeIcon: PathsIconActive,
    GateProps: { permissions: [LEARNING_VIEW] },
  },
  {
    pathname: '/circles',
    label: <FormattedMessage id="navigation.circles" />,
    icon: CirclesIcon,
    activeIcon: CirclesIconActive,
    GateProps: { permissions: [LEARNING_VIEW] },
  },
];

const NavigationDrawer = ({ open, toggleOpen, isMobile }) => {
  const location = useLocation();

  const activeIndex = DRAWER_NAVIGATION.findIndex((item) =>
    location.pathname.startsWith(item.pathname),
  );

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      anchor={isMobile ? 'right' : 'left'}
      open={open}
      onClose={toggleOpen}
      sx={{
        zIndex: (theme) =>
          isMobile ? theme.zIndex.modal + 1 : theme.zIndex.drawer,
        width: DRAWER_WIDTH,
        flexShrink: { sm: 0 },
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile
      }}
    >
      <Toolbar />
      <List>
        {DRAWER_NAVIGATION.map((props, index) => (
          <NavigationItem
            key={index}
            ButtonProps={{ dense: !isMobile }}
            TypographyProps={{ variant: isMobile ? 'body1' : 'h6' }}
            color={isMobile ? 'text.primary' : 'text.secondary'}
            isActive={activeIndex === index}
            {...props}
          />
        ))}
        {isMobile &&
          MENU_NAVIGATION.map((props, index) => (
            <NavigationItem key={index} color="text.primary" {...props} />
          ))}
        {isMobile && (
          <PopoverItem
            icon={PrivacyIcon}
            label={<FormattedMessage id="navigation.privacy" />}
            popover={PrivacyDialog}
            fullScreen
            sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}
          />
        )}
        {isMobile && <LanguageSelectItem />}
        {isMobile && <LogoutItem />}
      </List>
    </Drawer>
  );
};

NavigationDrawer.propTypes = {};

NavigationDrawer.displayName = 'NavigationDrawer';
export default NavigationDrawer;
