import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ContentRenderer from 'components/ContentRenderer';
import ErrorBoundary from 'components/ErrorBoundary';
import { useGetPrivacyPolicy } from 'core/api/static';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const PrivacyDialog = ({ link, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const { data, isLoading, isSuccess, error } = useGetPrivacyPolicy();

  return (
    <Dialog
      fullScreen={isMobile}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}
      {...props}
    >
      {isLoading && (
        <Box m={10} textAlign="center">
          <CircularProgress size={20} />
        </Box>
      )}
      {isSuccess && (
        <ErrorBoundary error={error}>
          <DialogTitle>
            <Typography
              variant="h3"
              color="primary"
              component="h1"
              data-test="privacy-title"
            >
              {data.title}
            </Typography>
            <FormattedMessage id="action.cancel">
              {(text) => (
                <IconButton
                  aria-label={text}
                  className={classes.iconButton}
                  onClick={props.onClose}
                  data-test="cancel-button"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </FormattedMessage>
          </DialogTitle>
          <DialogContent dividers>
            <ContentRenderer content={data.content} />
          </DialogContent>
        </ErrorBoundary>
      )}
      <DialogActions>
        {link ? (
          <Button
            component={Link}
            to={link}
            variant="contained"
            data-test="continue-button"
          >
            <FormattedMessage id="action.continue" />
          </Button>
        ) : (
          <Button
            onClick={props.onClose}
            variant="contained"
            data-test="close-button"
          >
            <FormattedMessage id="action.close" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

PrivacyDialog.propTypes = {
  /* Provide a link if the continue button should go to a new page*/
  link: PropTypes.string,
  ...Dialog.propTypes,
};

PrivacyDialog.displayName = 'PrivacyDialog';
export default PrivacyDialog;
