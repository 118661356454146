import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Avatar from 'components/Avatar';
import { useStores } from 'core/hooks/useStores';
import { PhoneField, TextField } from 'components/FormField';
import { isValidPhone } from 'components/FormField/PhoneField';
import {
  errorNotification,
  successNotification,
} from 'components/Notifications';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}));

const Personal = observer(() => {
  const classes = useStyles();
  const intl = useIntl();

  const { authStore, userStore } = useStores();

  const { checkUsername } = authStore;
  const { user, update, updateAvatar } = userStore;

  const initialValues = {
    fullName: user.user.fullName || '',
    username: user.user.username || '',
    workEmail: user.employee.workEmail || '',
    personalEmail: user.user.personalEmail || '',
    preferredName: user.user.preferredName || '',
    personalPhone: user.user.personalPhone || '',
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(
      intl.formatMessage({ id: 'error.input.empty' }),
    ),
    username: Yup.string()
      .min(5, intl.formatMessage({ id: 'error.username.length' }))
      .max(32, intl.formatMessage({ id: 'error.username.length' }))
      .matches(
        /^[A-zÀ-ú0-9_.\s-]+$/,
        intl.formatMessage({ id: 'error.username.characters' }),
      )
      .required(intl.formatMessage({ id: 'error.input.empty' }))
      .test(
        'isAvailable',
        intl.formatMessage({ id: 'error.username.taken' }),
        (value) => (value === user.user.username ? true : checkUsername(value)),
      ),
    personalEmail: Yup.string().email(
      intl.formatMessage({ id: 'error.email.invalid' }),
    ),
    preferredName: Yup.string().required(
      intl.formatMessage({ id: 'error.input.empty' }),
    ),
    personalPhone: Yup.string()
      .matches(
        /^[^a-zA-Z]*$/,
        intl.formatMessage({ id: 'error.phone.invalid' }),
      )
      .test(
        'isValid',
        intl.formatMessage({ id: 'error.phone.invalid' }),
        (value) => isValidPhone(value),
      ),
  });

  const onAvatarUpload = (e) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const dataUri = reader.result;
      try {
        await updateAvatar({ dataUri });
        successNotification(
          intl.formatMessage({ id: 'profile.settings.update.success' }),
        );
      } catch {
        errorNotification(intl.formatMessage({ id: 'error.generic' }));
      }
    };
    reader.readAsDataURL(e.currentTarget.files[0]);
    e.target.value = null;
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await update(values);
      successNotification(
        intl.formatMessage({ id: 'profile.settings.update.success' }),
      );
    } catch {
      errorNotification(intl.formatMessage({ id: 'error.generic' }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box flex="1 1 auto" ml={{ xs: 0, sm: 5, md: 7 }}>
      <Typography variant="h1">
        <FormattedMessage id="profile.settings.personal" />
      </Typography>
      <Box py={3}>
        <Avatar
          src={user.user.avatarUrl}
          name={user.user.fullName}
          size="large"
        />
        <input
          accept="image/*"
          className={classes.input}
          id="file-upload"
          type="file"
          onChange={onAvatarUpload}
        />
        <label htmlFor="file-upload">
          <Button variant="outlined" component="span" sx={{ ml: 2 }}>
            <FormattedMessage id="action.editAvatar" />
          </Button>
        </label>
      </Box>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <Stack spacing={2}>
              <TextField
                name="fullName"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.fullName" />}
              />
              <TextField
                name="username"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.username" />}
              />
              <TextField
                name="workEmail"
                fullWidth
                disabled
                margin="dense"
                label={<FormattedMessage id="user.workEmail" />}
              />
              <TextField
                name="personalEmail"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.personalEmail" />}
              />
              <TextField
                name="preferredName"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.nickname" />}
              />
              <PhoneField
                name="personalPhone"
                fullWidth
                margin="dense"
                label={<FormattedMessage id="user.phone" />}
              />
            </Stack>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 3, float: 'right' }}
              disabled={!isValid || isSubmitting || !dirty}
            >
              <FormattedMessage id="action.save" />
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
});

Personal.displayName = 'Personal';
export default Personal;
