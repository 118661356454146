import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Bullet from '@mui/icons-material/Stop';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import OpenIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ErrorBoundary from 'components/ErrorBoundary';
import { useGetPathReferences, useSaveEvent } from 'core/api/path';

const useStyles = makeStyles((theme) => ({
  item: {
    alignItems: 'start',
  },
  bullet: {
    minWidth: theme.spacing(4),
    marginTop: 4,
  },
}));

const Guides = ({ programId, contentId, className, parentContentId }) => {
  const classes = useStyles();

  const { data, isLoading, isSuccess, error } = useGetPathReferences(contentId);
  const { mutate: saveEvent } = useSaveEvent();

  useEffect(() => {
    if (programId !== 'preview') {
      saveEvent({
        programId,
        contentType: 'REFERENCE',
        contentId,
        parentContentId,
        event: 'opened',
      });
    }
  }, [programId, contentId, saveEvent]);

  const onDownloadResource = () => () => {
    if (programId !== 'preview') {
      saveEvent({
        programId,
        contentType: 'REFERENCE',
        contentId,
        parentContentId,
        event: 'downloaded',
      });
    }
  };

  return (
    <ErrorBoundary error={error}>
      <div className={className}>
        {isLoading && (
          <Box m={10} textAlign="center">
            <CircularProgress size={20} />
          </Box>
        )}
        {isSuccess && (
          <Box mt={2}>
            <List disablePadding>
              {data
                .filter((reference) => reference.type === 'Guide')
                .map(({ id, title, asset }) => (
                  <ListItem key={id} disableGutters className={classes.item}>
                    <ListItemIcon className={classes.bullet}>
                      <Bullet fontSize="inherit" color="primary" />
                    </ListItemIcon>
                    <Box>
                      <Typography>{title}</Typography>
                    </Box>
                    <ListItemSecondaryAction>
                      <a
                        onClick={onDownloadResource(id)}
                        href={asset.file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenIcon fontSize="small" color="primary" />
                      </a>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </Box>
        )}
      </div>
    </ErrorBoundary>
  );
};

Guides.propTypes = {
  programId: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  className: PropTypes.string,
  parentContentId: PropTypes.string.isRequired,
};

Guides.displayName = 'Guides';
export default Guides;
