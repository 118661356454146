import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import Chart from './Chart';
import ErrorBoundary from 'components/ErrorBoundary';
import { useGetEmployeeStats } from 'core/api/feed';

const SKILL_COLORS = [
  '#376870',
  '#F0DC7C',
  '#116616',
  '#8E3958',
  '#1061AD',
  '#6848AA',
];

const Stats = () => {
  const [type, setType] = useState('categories');
  const [skills, setSkills] = useState();
  const [skillsProcessed, setSkillsProcessed] = useState(false);

  const { data, isLoading, error } = useGetEmployeeStats();

  useEffect(() => {
    // add "other" section to skills if > 6
    if (!isLoading) {
      if (data.skills.length > 6) {
        const other = data.skills.slice(5);
        setSkills([
          ...data.skills.slice(0, 5),
          {
            title: 'Other',
            count: other.reduce((acc, cur) => acc + cur.count, 0),
            percentage: other.reduce(
              (acc, cur) => acc + parseInt(cur.percentage),
              0,
            ),
            items: other,
          },
        ]);
      } else {
        setSkills(data.skills);
      }
      setSkillsProcessed(true);
    }
  }, [isLoading]);

  // if nothing in skills or categories, just don't show it.
  if (
    !isLoading &&
    (data.skills.length === 0 || data.categories.length === 0)
  ) {
    return null;
  }

  return (
    <Paper
      sx={{ p: 2, mb: 2, width: '100%', borderRadius: 2 }}
      elevation={0}
      variant="outlined"
    >
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'left', md: 'center' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Typography variant="h6">Stats</Typography>
        <ToggleButtonGroup
          value={type}
          exclusive
          size="small"
          color="primary"
          onChange={(e, value) => setType(value)}
          sx={{ height: 30, mt: { xs: 1, md: 0 } }}
        >
          <ToggleButton
            value="categories"
            sx={{ width: { xs: '50%', md: 156 } }}
          >
            Categories
          </ToggleButton>
          <ToggleButton value="skills" sx={{ width: { xs: '50%', md: 156 } }}>
            Skills
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {skillsProcessed && (
        <ErrorBoundary error={error}>
          {type === 'categories' && (
            <Chart
              data={data.categories}
              colors={data.categories.map((item) => `rgba(${item.color})`)}
            />
          )}
          {type === 'skills' && (
            <Chart
              data={skills}
              colors={SKILL_COLORS}
              hasMore={data.skills.length > 6}
            />
          )}
        </ErrorBoundary>
      )}
    </Paper>
  );
};

Stats.displayName = 'Stats';
export default Stats;
