import { observable, runInAction, action } from 'mobx';
import { task } from 'mobx-task';

import { UserService, AuthService } from 'core/services';

export default class UserStore {
  @observable
  user = null;
  @observable
  loggedIn = false;
  @observable
  attributes = null;

  @task()
  setLoggedIn = (flag) => {
    runInAction(() => (this.loggedIn = flag));
  };

  @action
  reset = () => {
    this.user = null;
  };

  @task({ swallow: true })
  get = async () => {
    const data = await UserService.get();
    runInAction(() => (this.user = data));
    this.setLoggedIn(true);
  };

  @task
  update = async (params) => {
    const data = await UserService.update(params);
    await AuthService.getNewToken();
    runInAction(() => (this.user = { ...this.user, ...data }));
  };

  @task.resolved
  updateAvatar = async (dataUri) => {
    const { url } = await UserService.updateAvatar(dataUri);
    runInAction(() => (this.user.user.avatarUrl = url));
  };

  @task
  getAttributesByName = async (attributes) =>
    await UserService.getAttributesByName({ attributes });

  @task.resolved
  updateAttributes = async (params) => {
    const data = await UserService.updateAttributes(params);
    runInAction(() => {
      this.attributes = data;
    });
  };

  @task.resolved
  updateHasOnboarded = async () => {
    await UserService.updateAttributes([
      { name: 'has_onboarded_01', values: [{ value: 'true' }] },
    ]);
    runInAction(() => (this.user = { ...this.user, hasOnboarded: 'true' }));
  };

  @action
  hasPermission = ({ requiredPerms, any = false }) => {
    if (requiredPerms.length > 0) {
      const userPerms = this.user.permissions || [];
      return any
        ? requiredPerms.some((p) => userPerms.includes(p))
        : requiredPerms.every((p) => userPerms.includes(p));
    }
    return true;
  };

  @task
  updateLanguagePreference = async (languageCode) => {
    const language = await UserService.updateLanguagePreference(languageCode);
    await AuthService.getNewToken();
    runInAction(() => {
      this.user.employee.language = language;
    });
  };
}
