import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import DesktopNavigation from './components/DesktopNavigation';
import EmptyState from 'components/EmptyState';
import ErrorBoundary from 'components/ErrorBoundary';
import Gate from 'components/Gate';
import Layout from 'layouts/Layout';
import MobileNavigation from './components/MobileNavigation';
import Step from './Step';
import Tracker from 'core/utils/tracker';
import { errorNotification } from 'components/Notifications';
import { LEARNING_VIEW } from 'core/utils/permissions';
import {
  FormWizard,
  FormWizardSteps,
  FormWizardStep,
  FormWizardStepper,
} from 'components/FormWizard';
import {
  useGetProgramExperience,
  useSaveEvent,
  useSaveResponses,
} from 'core/api/path';

const Experience = ({
  match: {
    params: { programId, pathId, experienceId },
  },
}) => {
  const intl = useIntl();

  const theme = useTheme();
  const isSmall = useMediaQuery(() => theme.breakpoints.down('md'));

  const { mutateAsync: saveEvent } = useSaveEvent();
  const { mutate: saveResponses } = useSaveResponses();

  const { data, isLoading, error } = useGetProgramExperience(
    programId,
    pathId,
    experienceId,
  );

  const [done, setDone] = useState(false);

  const isPreview = programId === 'preview';
  const isClosed = data?.program?.isClosed;
  const programLink = isPreview ? null : `/paths/${programId}`;

  useEffect(() => {
    if (!isPreview) {
      saveEvent({
        programId,
        contentType: 'EXPERIENCE',
        contentId: experienceId,
        parentContentId: pathId,
        event: 'started',
      });

      Tracker.trackEvent('experience_start', {
        program_id: programId,
        path_id: pathId,
        experience_id: experienceId,
      });
    }
  }, [isPreview, saveEvent, programId, pathId, experienceId]);

  if (done) {
    return <Redirect to={programLink} push={true} />;
  }

  const onStepSubmit = async (
    values,
    { setSubmitting, activeStep, nextStep, isLastStep },
  ) => {
    if (isPreview || isClosed) {
      if (isLastStep && !isPreview) {
        setDone(true);
      } else {
        setSubmitting(false);
        nextStep();
      }
    } else {
      if (Object.keys(values).length > 0) {
        try {
          await saveResponses({
            programId,
            contentType: 'experience',
            contentId: experienceId,
            responses: values,
          });
        } catch {
          errorNotification(intl.formatMessage({ id: 'error.generic' }));
        }
      }
      const stepId = data.desktopSteps[activeStep].id;
      saveEvent({
        programId,
        contentType: 'EXPERIENCE_STEP',
        contentId: stepId,
        parentContentId: experienceId,
        event: 'completed',
      });
      setSubmitting(false);
      if (isLastStep) {
        setDone(true);
        await saveEvent({
          programId,
          contentType: 'EXPERIENCE',
          contentId: experienceId,
          parentContentId: pathId,
          event: 'completed',
        });
        Tracker.trackEvent('experience_complete', {
          program_id: programId,
          path_id: pathId,
          experience_id: experienceId,
        });
      } else {
        nextStep();
      }
    }
  };

  const renderExperience = ({
    program,
    title,
    desktopSteps,
    responses = {},
    ...props
  }) => {
    // Logic to start the experience at step X
    // where X is the employee's last completed step index + 1
    let startAtStep = 0;
    if (!isPreview) {
      const completedSteps = program.itemStatus[experienceId]?.completedItems;
      // start at 0 if no progress at all or if all steps have been completed
      if (completedSteps && completedSteps.length !== desktopSteps.length) {
        const nextStepIdx = desktopSteps.findIndex(
          (step) => !completedSteps.includes(step.id),
        );
        if (nextStepIdx > -1) {
          startAtStep = nextStepIdx;
        }
      }
    }

    const navigationProps = {
      isPreview,
      pathId,
      experienceId,
      programId,
      program,
      title,
      programLink,
      ...props,
    };

    return (
      <Layout color="light" disablePadding showAppbar={!isSmall}>
        <FormWizard
          initialValues={responses}
          startAtStep={startAtStep}
          validateOnChange
          validateOnBlur={false}
        >
          {isSmall ? (
            <Box display="flex" alignItems="center" p={1}>
              <IconButton
                component={NavLink}
                to={isPreview ? '/admin/experiences/paths' : programLink}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
            </Box>
          ) : (
            <DesktopNavigation {...navigationProps} />
          )}
          <FormWizardStepper>
            {({ totalSteps, activeStep }) => {
              return (
                <LinearProgress
                  value={((activeStep + 1) / totalSteps) * 100 || 0}
                  variant="determinate"
                  thickness={5}
                />
              );
            }}
          </FormWizardStepper>
          <FormWizardSteps>
            {desktopSteps.map(
              ({
                id,
                contentType,
                content,
                column1Content,
                column2Content,
              }) => (
                <FormWizardStep key={`step-${id}`} onSubmit={onStepSubmit}>
                  <Step
                    id={id}
                    programId={programId}
                    program={program}
                    experienceId={experienceId}
                    isClosed={isClosed}
                    isPreview={isPreview}
                    columns={
                      contentType === 'experienceStep'
                        ? [content]
                        : [column1Content, column2Content]
                    }
                  />
                </FormWizardStep>
              ),
            )}
          </FormWizardSteps>
          {isSmall && <MobileNavigation {...navigationProps} />}
        </FormWizard>
      </Layout>
    );
  };

  return (
    <Gate permissions={[LEARNING_VIEW]} redirectOnDenied>
      <ErrorBoundary error={error}>
        {isLoading && <EmptyState isLoading />}
        {!isLoading && renderExperience(data)}
      </ErrorBoundary>
    </Gate>
  );
};

Experience.propTypes = {
  match: PropTypes.object.isRequired,
};

Experience.displayName = 'Experience';
export default observer(Experience);
