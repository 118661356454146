import ApiService from './ApiService';

export const get = () => ApiService.get('auth/me');

export const update = async (data) => ApiService.put('user', data);

export const updateAvatar = async (data) => ApiService.put('user/avatar', data);

export const getActivity = async ({ limit, page }) => {
  const data = await ApiService.get(
    `feed/employees/learning-history/${limit}`,
    {
      params: { page },
    },
  );
  return data.coachContent;
};

export const getAttributes = () => ApiService.get('user/attributes');

export const getAttributesByName = (params) =>
  ApiService.get('user/attributes/list', { params });

export const updateAttributes = (data) =>
  ApiService.post('user/attributes', data);

export const getEmploymentHistory = () => ApiService.get('user/employment');

export const updateEmploymentHistory = (data) =>
  ApiService.post('user/employment', data);

export const getNotificationPreferences = () =>
  ApiService.get('user/notifications');

export const updateNotificationPreferences = (data) =>
  ApiService.post('user/notifications', data);

export const updateLanguagePreference = (data) =>
  ApiService.post('user/language', data);

export const getBadges = () => ApiService.get('game/badges');

export const updateShare = async (data) =>
  ApiService.post('content/share', data);
