import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'core/hooks/useStores';
import Container from 'containers/auth/components/Container';
import IdentifierForm from './IdentifierForm';
import OtpForm from './OtpForm';
import PasswordForm from './PasswordForm';
import SsoRedirect from './SsoRedirect';

const Login = observer(() => {
  const history = useHistory();
  const { authStore } = useStores();
  const { targetUrl: url } = authStore;
  const query = new URLSearchParams(window.location.search);
  const targetUrl = query.get('targetUrl') || url;
  const user = query.get('user');
  const [authConfig, setAuthConfig] = useState({});
  const [identifier, setIdentifier] = useState(user);

  const onIdentifierSuccess = (id, config) => {
    setIdentifier(id);
    setAuthConfig(config);
  };

  const onSuccess = () => {
    history.push(targetUrl || '/');
  };

  return (
    <Container>
      {!authConfig.authType && (
        <IdentifierForm onSuccess={onIdentifierSuccess} targetUrl={targetUrl} />
      )}
      {authConfig.authType === 'simple' && (
        <PasswordForm identifier={identifier} onSuccess={onSuccess} />
      )}
      {authConfig.authType === 'otp' && (
        <OtpForm identifier={identifier} onSuccess={onSuccess} />
      )}
      {authConfig.authType === 'sso' && <SsoRedirect url={authConfig.ssoUrl} />}
    </Container>
  );
});

Login.displayName = 'Login';

export default Login;
